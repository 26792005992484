body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.kc-page-title span {
    text-transform: uppercase;
    color: #55a3a6;
}

.form-control:focus {
    border-color: #55a3a6;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 136, 206, .6);
}

.control-label {
    text-transform: uppercase;
    font-weight: 300
}

.checkbox label {
    text-transform: uppercase;
    font-weight: 300
}

#kc-registration > span:nth-child(1) > span:nth-child(1) {
    text-transform: uppercase;
    font-weight: 300
}

#kc-registration > span:nth-child(1) > a:nth-child(2) > span:nth-child(1) {
    text-transform: uppercase;
    font-weight: 300
}

div.form-group:nth-child(3) > div:nth-child(2) > span:nth-child(1) > a:nth-child(1) > span:nth-child(1) {
    text-transform: uppercase;
    font-weight: 300
}

#kc-login {
    text-transform: uppercase;

}

#kc-page-title > span:nth-child(1) {
    text-transform: uppercase;

}

.form-control:hover {
    border-color: #55a3a6;
}

.login-pf a:hover {
    color: #55a3a6;
}

.login-pf a {
    color: #55a3a6;
}

.cs-button-block {
    background-color: #ffffff;
    background-image: unset;
    border: 1px solid #55a3a6;
    color: #55a3a6;
}

.cs-button-block:hover {
    background-color: #55a3a6;
    background-image: unset;
    border: 1px solid #55a3a6;
    color: #ffffff;
}

.cs-html body {
    background-color: #ffffff;
}

.cs-wrapper span {
    display: none;
}

.cs-wrapper {
    background-image: url("https://images.squarespace-cdn.com/content/v1/636ba6e36654f727a90868e4/e1f8cd3e-62ac-4e5d-a1cb-910039eb12d6/Middel+1.png?format=300w");
    height: 92px;
    width: 300px;
    margin: 0 auto;
}
